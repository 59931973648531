import React, { Component } from "react";
import { ethers } from "ethers";
import Mint from "./components/Mint";
import Home from "./components/Home"
import Marketplace from "./components/Marketplace";
import MyNFT from "./components/MyNFT";
import Help from "./components/Help";
import { BrowserRouter, Routes, Route } from "react-router-dom";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    return (
      <div>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/mint" element={<Mint />} />
              <Route path="/marketplace" element={<Marketplace />} />
              <Route path="/mynft" element={<MyNFT />} />
              <Route path="/help" element={<Help />} />
            </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
