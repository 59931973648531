import React, { Component } from "react";
import { ethers } from "ethers";
import Mint from "./Mint";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {Header} from "./Header";
import {Footer} from "./Footer"
import degisikFoto from "../img/home-banner.gif"
import Card from "./Card"
import {MintABI, CONT_ADDRESS} from "../contract/MintABI"
import Web3 from "web3/dist/web3.min";
import { CHAIN_ID } from "../config/keys"


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: null,
      balance: null,
      modal_pending: false,
      metadata: {
        name:"NFT",
        image: " ",
        description: "Empty"
      },
      price: "0",
      tokenID: " ",
      balance: 0,
      currentOwner: " ",
      totalTokenCount: 0,
      allNFTS: [],
    };
  }

  componentDidMount = async () => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(MintABI, CONT_ADDRESS);
    const totalTokenCount = await contract.methods._tokenIdCounter().call()
    this.setState({totalTokenCount: totalTokenCount})
    let i = totalTokenCount
    while (i>=0 && i>=totalTokenCount-5) {
      const nft = await contract.methods.allNFTS(i).call()
          let uri = nft.tokenURI//NFT METADATA URI
          if (nft.forSale && !nft.uniqueState) {
            //this.setState({allNFTS: [...this.state.allNFTS, nft]});
            fetch(uri).then((respo) => {
              return respo.json();
            }).then((json) => {
              console.log(json.name);
              let url = json.image.split("//")[1];
              url = "https://ipfs.io/ipfs/" + url;//NFT IMAGE REFORMAT
              json.imageURL = url;
              nft.image = url;
              nft.name = json.name;
              nft.description =json.description
              this.setState({allNFTS: [...this.state.allNFTS, nft]});
              console.log(this.state.allNFTS);
            })
          }
          i--
    }
  }

  metamaskBaglan = async () => {
    if (typeof window.ethereum !== "undefined") {
      console.log("MetaMask is installed!");

      const chainId = await window.ethereum.request({ method: `eth_chainId` });
            const bagethChainId = CHAIN_ID

            if (chainId === bagethChainId) {
                console.log("BAGETH network");
            } else {
                console.log("Different network")
            }

            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: CHAIN_ID }],
                });
                console.log("You have succefully switched to BAGETH network")
                const acc = await window.ethereum.request({ method: "eth_requestAccounts" })
                console.log(acc);
                this.setState({ address: acc[0], buttonHide: "hidden"});
                await this.getBalance(acc[0])
                window.location.href = "/marketplace"
            } catch (switchError) {
                // This error code indicates that the chain has not been added to MetaMask.
                console.log(switchError)

                if (switchError.code === 4902) {
                    console.log("This network is not available in your metamask, please add it")
                    // TODO Kullaniciya hatayi bildir
                }
                console.log("Failed to switch to the network")
            }


      
    } else {
      alert("Metamask Bulunamadi");
    }
  };

  getBalance = async (address) => {
    window.ethereum
      .request({
        method: "eth_getBalance",
        params: [String(address), "latest"],
      })
      .then((balance) =>
        this.setState({ balance: ethers.utils.formatEther(balance) })
      );
    if (address !== null) {
      localStorage.setItem("address", address);
    }
  }


  

  render() {
    return (
      <div className="w-full bg-gray-100 flex justify-between flex-col min-h-screen ">
        <div className="flex flex-col w-full">
          <Header />
          <div className="bg-[#395B64]/70 relative shadow-lg w-full py-36 bg-gradient-to-r from-[#2C3333] to-black text-white">
            <img className="opacity-30 object-cover absolute top-0 w-full h-full" src={degisikFoto}/>
            <div className="absolute top-1/2 left-1/2 flex flex-col -translate-x-1/2 -translate-y-1/2 gap-y-12 items-center text-center">
              <div className="flex flex-col">
                <h2 className="text-2xl font-bold text-white">BAGETH NFT Market</h2>
                <span className="border border-white"></span>
              </div>
              <p>NFT yaratmak, satmak ve takaslamak için tasarlanmış bir dijital market.</p>
              <div className="flex justify-between w-full px-32 items-center gap-x-8">
              <a href="/help" className="py-3 px-6 bg-blue-600 shadow-lg rounded-lg hover:bg-blue-500 text-white font-bold cursor-pointer text-xs md:text-sm w-20 md:w-36 flex justify-center">Yardım</a>
              <button className="py-3 px-6 bg-gray-700 shadow-lg rounded-lg hover:bg-gray-600 text-white font-bold cursor-pointer text-xs md:text-sm w-20 md:w-48 flex justify-center" onClick={() => this.metamaskBaglan()}>NFT'leri Keşfet</button>
              </div>
              
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center h-full py-8 gap-y-8">
          <h2 className="px-16 text-lg font-semibold">Son NFT'ler</h2>
        <div className="w-full px-16 rounded-xl flex flex-wrap overflow-x-hidden">
        <div className="flex gap-x-4 gap-y-8 h-full flex-wrap justify-center">
          {this.state.allNFTS.map((nft) => {
            return(
              <Card 
                key={nft.tokenID}
                nftImage={nft.image} 
                nftName={nft.name} 
                nftDescription={nft.description}
                nftUnique={nft.uniqueState} 
                nftPrice={ethers.utils.formatEther(nft.price)}
                currentOwner={nft.currentOwner}
                mintedBy={nft.mintedBy} 
                tokenId={nft.tokenID}
                buyAndSwap={"hidden"}
              />
            )
          })}
          
        </div>
        </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
