import React, { Component } from "react";
import Web3 from "web3/dist/web3.min";
import Switch from "react-switch";
import { TiTick } from 'react-icons/ti';
import { FiCopy } from 'react-icons/fi';
import {MintABI, CONT_ADDRESS} from "../contract/MintABI"
import { ethers } from "ethers"


class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: localStorage.getItem("address"),
      tickButton: "hidden",
      newPrice: this.props.nftPrice,
      checked: this.props.forSale
    };
  }

  handleChange = async (tokenID) => {
    
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(MintABI, CONT_ADDRESS);
    await contract.methods.toggleForSale(tokenID).send({
      from: localStorage.getItem("address")
    })
    this.setState({ checked: !this.state.checked});
  }

  priceChange = async (tokenID, newPrice) => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(MintABI, CONT_ADDRESS);
    let price = ethers.utils.parseEther(newPrice);
    console.log(price)
    await contract.methods.changeTokenPrice(tokenID, String(price)).send({
      from: localStorage.getItem("address")
    })
    window.location.href = "/mynft"
  }

  copyClipboard = (id) => {
     // Get the text field
    var copyText = document.getElementById(id);

  // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

   // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);
  }

  render() {
    return (
      
      <div className="flex flex-col w-72 h-[420px] min-w-max  bg-white border shadow-lg rounded-2xl items-center justify-between">
        <div className="py-4 flex flex-col items-center justify-between w-full h-full">

       
        <img src={this.props.nftImage} className="w-48 h-32 bg-black rounded-2xl " />
        <span className="font-semibold">{this.props.nftName}</span>
        <span className="text-xs">{this.props.nftDescription}</span>
        <div className="border border-gray-700 w-4/5"></div>
        <span className={(this.props.nftUnique==false ? "block" :"hidden")}>{this.props.nftPrice} ETH</span>
        <div className="flex flex-col gap-y-4 items-center justify-center text-sm">
            <button className={"py-1 px-12 bg-blue-600 shadow-lg rounded-lg hover:bg-blue-500 text-white font-bold " + this.props.buyAndSwap} onClick={() => this.props.buyButton(this.props.tokenId,this.props.nftPrice.toString())}>Satın Al</button>
            <button className={"py-1 px-12 bg-gray-700 shadow-lg rounded-lg hover:bg-gray-600 text-white font-bold " + this.props.buyAndSwap} onClick={() => this.props.openModal(this.props.tokenId,this.props.nftPrice.toString())}>Takas</button>
            <button className={"py-1 px-12 bg-red-600 shadow-lg rounded-lg hover:bg-red-500 text-white font-bold " + (this.props.selectButton == "block" ? "block" : "hidden")} 
            onClick={() => this.props.selectButtonClick(this.props.receiverTokenID,this.props.receiverNftPrice,this.props.tokenId,this.props.nftPrice.toString())} >Seç</button>
            <div className={(this.props.switchButton==true ? "block" :"hidden")+" gap-y-2 items-center justify-center flex flex-col"} >
            <button className={"py-1 px-8 bg-gray-600 shadow-lg rounded-lg hover:bg-gray-500 text-white font-bold text-sm"} onClick={()=> this.props.openModalTransfer(this.props.tokenId)} >Transfer</button>
            <div className={"flex gap-x-4 items-center "+(this.props.nftUnique==false ? "block" :"hidden")}>
                <span>Satış:</span>
                <Switch height={20} width={42} onColor={"#4299e1"} offColor={"#f56565"} onChange={() =>this.handleChange(this.props.tokenId)} checked={this.state.checked ? this.state.checked : false} />
              </div>
              <div className={"items-center justify-center flex flex-row gap-x-2 "+(this.props.nftUnique==false ? "block" :"hidden")}>
              <span>Fiyat:</span>
              <input type="text" className="w-[35%]  rounded-full px-2 text-sm outline-none text-center border shadow-md" value={this.state.newPrice} onChange={(evt) => {
                if(evt.target.value != this.props.nftPrice) 
                {
                  this.setState({tickButton: "block", newPrice: evt.target.value})
                } 
                else
                {
                  this.setState({tickButton: "hidden", newPrice: evt.target.value})
                }
                }}/>
              <button className={"w-6 h-6 bg-green-600 shadow-lg rounded-full hover:bg-green-500 text-white font-semibold text-l flex justify-center items-center " + this.state.tickButton} onClick={() => this.priceChange(this.props.tokenId, this.state.newPrice)}><TiTick/></button>
              </div>
            </div>
        </div>
        </div>
        <div className={(this.props.switchButton==true ? "hidden " :"block ")+"w-full bg-gray-200 rounded-b-xl py-4 px-6 text-xs flex justify-center gap-x-2 font-semibold"}>
        <input id={"token-" + this.props.tokenId} value={this.props.currentOwner} type="text" readOnly  className="hidden"/>
        <span>NFT Sahibi:</span>
        <button className="flex gap-x-1 text-blue-600 hover:text-blue-500 cursor-pointer" onClick={() => this.copyClipboard(`token-${this.props.tokenId}`)}>
          <FiCopy />
          <span className="font-semibold">Adres</span>
        </button>
        </div>
        
        <div className={(this.props.switchButton==true ? "block " :"hidden ")+"w-full bg-gray-200 rounded-b-xl py-4 px-6 text-xs flex justify-center gap-x-2 font-semibold"}>
        <input id={"token-" + this.props.tokenId} value={this.props.currentOwner} type="text" readOnly  className="hidden"/>
        <span className="">Token ID:</span>
        <span className="font-semibold text-blue-600">{this.props.tokenId}</span>
        </div>

      </div>
    );
  }
}

export default Card;
