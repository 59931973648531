import React, { Component } from "react";
import Web3 from "web3/dist/web3.min";
import Card from "./Card"
import {getJsonMetaData} from "../config/ipfs"
import {MintABI, CONT_ADDRESS} from "../contract/MintABI"
import { ethers } from "ethers"
import ReactLoading from 'react-loading';

class ModalTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: localStorage.getItem("address"),
      transferAddress: " ",
      loadingFlag: false,
      errorFlag: false,
      confirmFlag: false
    };
  }

  transferToken(tokenID,transferAddress){
    tokenID = Number(tokenID);

    this.setState({loadingFlag:true})

    console.log(transferAddress)
    if(transferAddress.length<10){
        this.setState({loadingFlag:false})
        this.setState({errorFlag:true})


        setTimeout(function () {
          this.setState({ errorFlag: false });
        }.bind(this), 3000);
        return;
    }else{
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(MintABI, CONT_ADDRESS);  
        contract.handleRevert = true;//rpc mesaji

        contract.methods.transferToken(tokenID,transferAddress).send({
            from:localStorage.getItem("address")
        }).then(
            (res) => {
              console.log(res)
              this.setState({loadingFlag:false})
              this.setState({confirmFlag:true})
              //REQ FONK HATA MESAJI GORUNTULE.RPC MESAJI COK BASILIYOR.
              setTimeout(function () {
                this.setState({ confirmFlag: false });
              }.bind(this), 3000);
            }
          ).catch((res =>{
              console.log(res)
              this.setState({loadingFlag:false})
              this.setState({errorFlag:true})
      
      
              setTimeout(function () {
                this.setState({ errorFlag: false });
              }.bind(this), 3000);
          }))
    }
  }


  render() {
    //RESIM YUKLEMEYI DUZENLE
    return (
      <div className="inset-0 z-40 fixed flex flex-col items-center justify-center gap-y-4" >
        <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
              onClick={() => this.props.closeModalTransfer()}
            />
        
        <div className="w-[30%] max-h-[45%] bg-gray-100 p-8 rounded-xl flex flex-wrap flex-col relative justify-center gap-y-2">
            <div className="relative flex flex-row items-center fixed ">
                <span className="w-full flex justify-center items-center text-xl font-semibold text-black">Transfer</span>
                <span className="absolute top-0 right-0 font-bold text-xl hover:text-gray-600 cursor-pointer" onClick={() => this.props.closeModalTransfer()}>X</span>
            </div>
            <div className="py-2 flex flex-row w-full gap-x-2 items-center" >
                <span className="font-bold text-sm" >Adres:</span>
                <input className="rounded-lg border shadow-md px-2 text-sm outline-none py-1 w-full" type="text" onChange={(evt)=>this.setState({transferAddress:evt.target.value})}/>
            </div>
            <div className="w-full flex items-center justify-center">
                <button className={"py-1 px-8 w-[30%] bg-gray-600 shadow-lg rounded-lg hover:bg-gray-500 text-white font-bold text-sm "} onClick={()=>this.transferToken(this.props.transferTokenID,this.state.transferAddress) }>Gönder</button>
            </div>
        </div>

        {this.state.loadingFlag ?
                    <div className="z-20 flex items-center justify-center gap-x-4 bg-blue-400 w-3/4 md:w-1/3 text-xs md:text-base p-3 md:p-4 text-white font-semibold shadow-md rounded-md"><ReactLoading className="text-white text-center my-auto md:mt-1" type='spin' height='16px' width='16px' /><span>İşlem gerçekleşiyor...</span></div> : null}
        {this.state.errorFlag ? <div className="z-20 flex items-center justify-center bg-red-400 w-3/4 md:w-1/3 text-xs md:text-base p-3 md:p-4 text-white font-semibold shadow-md rounded-md"> İşlem sırasında sorun oluştu.</div> : null}
        {this.state.confirmFlag ? <div className="z-20 flex items-center justify-center bg-green-400 w-3/4 md:w-1/3 text-xs md:text-base p-3 md:p-4 text-white font-semibold shadow-md rounded-md"> İşlem başarıyla gerçekleşti </div> : null}

      </div>
    );
  }
}

export default ModalTransfer;