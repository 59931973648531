import React, { Component } from "react";
import Web3 from "web3/dist/web3.min";
import { MintABI, CONT_ADDRESS } from "../contract/MintABI";
import { uploadToNetwork, getJsonMetaData } from "../config/ipfs"
import { ethers } from "ethers"
import { Header } from "./Header"
import ReactLoading from 'react-loading';
import {Footer} from "./Footer"
import Switch from "react-switch";
import Banner from "../img/banner4.gif"

class Mint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: localStorage.getItem("address"),
      nft_url: null,
      nft_name: ' ',
      nft_desc: ' ',
      nft_price: '0',
      img_path: ' ',
      loadingFlag: false,
      errorFlag: false,
      confirmFlag: false,
      forSaleToggle: true,
      uniqueToggle: true

    };
    this.handleChangeForSale = this.handleChangeForSale.bind(this);
    this.handleChangeUnique = this.handleChangeUnique.bind(this);
  }

  handleChangeForSale = (checked) => {
    this.setState({ forSaleToggle : checked });
  }
  handleChangeUnique = (checked) => {
    this.setState({ uniqueToggle: checked });
  }

  fileUpload = (e) => {
    try {
      let file = e.target.files[0];
      this.setState({nft_url: file});

      const url = URL.createObjectURL(file)//Yuklenen goruntunun gosterilmesi
      this.setState({img_path : url})

    } catch (error) {
      console.log("error uploading file:", error);
    }
  };


  mintContract = async () => {
    
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(MintABI, CONT_ADDRESS);
    contract.handleRevert = true;
    const accounts = await web3.eth.getAccounts();
    const acc = accounts[0]
    console.log(acc)
    console.log(contract);

    //wei turunde number uretıldı.
    let price = ethers.utils.parseEther(this.state.nft_price);
    price = String(price.toString())
    console.log(price)

    this.setState({loadingFlag:true})
    const CIDnft = await uploadToNetwork(this.state.nft_name, this.state.nft_desc, this.state.nft_url);
    //metdatayi dagitik veri ambarinda depolar
    const metaDataUrl = `https://${CIDnft.ipnft}.ipfs.nftstorage.link/metadata.json`; //metadayi ipfs formatinde fetc eder.
    this.setState({loadingFlag:false})


    setTimeout(function () {
      this.setState({ loadingFlag: true });
    }.bind(this), 4000);

    contract.methods.safeMint(acc,metaDataUrl,price,this.state.forSaleToggle,!this.state.uniqueToggle)
        .send({ from: acc,
                uri: metaDataUrl })//buytoken icin cuzdan balansını value ile gonder.
        .then(balance => {
            console.log(balance)
            this.setState({loadingFlag:false})
            this.setState({confirmFlag:true})

            setTimeout(function () {
              this.setState({ confirmFlag: false });
            }.bind(this), 3000);
        })
        .catch((err) =>{
            console.log(err)
            this.setState({loadingFlag:false})
            this.setState({errorFlag:true})

            setTimeout(function () {
              this.setState({ errorFlag: false });
            }.bind(this), 3000);

          });
    return contract;

  };

  render() {
    //RESIM YUKLEMEYI DUZENLE
    return (
      <div className="w-full min-h-screen bg-gray-100 items-center justify-between flex flex-col gap-y-8">
      <div className="flex flex-col w-full">
        <Header />
        <div className="bg-[#395B64]/70 relative shadow-lg w-full py-20 bg-gradient-to-r from-black to-[#2C3333] flex items-center text-white text-2xl">
            <img className="w-full h-full object-cover absolute top-0 opacity-20" src={Banner}/>
            <span className="z-10 px-20">NFT Yarat</span>
          </div>
          </div>
        {this.state.loadingFlag ?
                    <div className="w-full flex items-center justify-center gap-x-4 bg-blue-400 w-3/4 md:w-1/3 text-xs md:text-base p-3 md:p-4 text-white font-semibold shadow-md rounded-md"><ReactLoading className="text-white text-center my-auto md:mt-1" type='spin' height='16px' width='16px' /><span>İşlem gerçekleşiyor...</span></div> : null}
        {this.state.errorFlag ? <div className="w-full flex items-center justify-center bg-red-400 w-3/4 md:w-1/3 text-xs md:text-base p-3 md:p-4 text-white font-semibold shadow-md rounded-md"> İşlem sırasında sorun oluştu.</div> : null}
        {this.state.confirmFlag ? <div className="w-full flex items-center justify-center bg-green-400 w-3/4 md:w-1/3 text-xs md:text-base p-3 md:p-4 text-white font-semibold shadow-md rounded-md"> İşlem başarıyla gerçekleşti </div> : null}


        <button className="absolute top-15 left-0 m-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"onClick={()=>window.location.href="/marketplace"}>Geri</button>
        <div className="flex flex-col gap-y-8 h-full items-center justify-center">
        <img className="object-cover h-48 w-48 rounded-full border-2 border-gray-300 shadow-md" src={this.state.img_path} />
          <div className="flex flex-col gap-y-2">
          <label className="py-2 px-12 bg-gray-700 shadow-lg rounded-lg hover:bg-gray-600 text-white font-bold hover:cursor-pointer">
          <input
            hidden
            accept=".jpeg, .jpg, .png, .svg"
            type="file"
            onChange={this.fileUpload}
            onBlur={this.fileUpload}
          />
            Resim Yükle
        </label>

          </div>
          <div className="flex flex-col gap-y-2">
            <span>İsim</span>
            <input type="text" className="px-4 py-1 rounded-lg border outline-none bg-white shadow-md" onChange={(evt) => {
              this.setState({nft_name: evt.target.value});            
            }}/>
          </div>
          <div className="flex flex-col gap-y-2">
            <span>Açıklama</span>
            <input type="text" className="px-4 py-1 rounded-lg border outline-none bg-white shadow-md" onChange={(evt) => {
              this.setState({nft_desc: evt.target.value});            
            }}/>
          </div>
          <div className="flex gap-x-2 items-center">
            <span>Ticari NFT:</span>
            <Switch height={20} width={42} onColor={"#4299E1"} offColor={"#F56565"} onChange={this.handleChangeUnique} checked={this.state.uniqueToggle}/>
          </div>
          <div className={"flex flex-col "+(this.state.uniqueToggle==true ? "block" :"hidden")}>
            <span>Fiyat</span>
            <input type="text" className="px-4 py-1 rounded-lg border outline-none bg-white shadow-md" onChange={(evt) => {
              this.setState({nft_price: evt.target.value});            
            }}/>
          </div>
          <div className="flex justify-center items-center w-full">
            <div className={"flex gap-x-2 items-center "+(this.state.uniqueToggle==true ? "block" :"hidden")}>
              <span>Satışa Açık:</span>
              <Switch height={20} width={42} onColor={"#4299E1"} offColor={"#F56565"} onChange={this.handleChangeForSale} checked={this.state.forSaleToggle}/>
            </div>
          </div>
          <button className="py-2 px-12 bg-blue-600 shadow-lg rounded-lg hover:bg-blue-500 text-white font-bold" onClick={this.mintContract}>NFT Yarat</button>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Mint;
