import React, { Component } from "react";
import Web3 from "web3/dist/web3.min";
import Card from "./Card"
import {getJsonMetaData} from "../config/ipfs"
import {MintABI, CONT_ADDRESS} from "../contract/MintABI"
import { ethers } from "ethers"
import ReactLoading from 'react-loading';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: localStorage.getItem("address"),
      metadata: {
        name:"NFT",
        image: " ",
        description: "Empty"
      },
      price: "0",
      tokenID: " ",
      balance: 0,
      currentOwner: " ",
      totalTokenCount: 0,
      allNFTS: [],
      loadingFlag: false,
      errorFlag: false,
      confirmFlag: false
    };
    this.selectButtonClick = this.selectButtonClick.bind(this)
  }


  componentDidMount = async () => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(MintABI, CONT_ADDRESS);

    window.ethereum.request({ method: "eth_requestAccounts" }).then((res) => {
      console.log(res);
      const address = res[0];
      localStorage.setItem("address",address)
      return address;
    }).then((add) => {
      this.getAccountBal(add);
    });

    const totalTokenCount = await contract.methods._tokenIdCounter().call()
    this.setState({totalTokenCount: totalTokenCount})
      for (let i = 0; i < totalTokenCount; i++) {
          const nft = await contract.methods.allNFTS(i).call()
          if (nft.currentOwner.toLowerCase() == localStorage.getItem("address") && nft.forSale && !nft.uniqueState) {
            let uri = nft.tokenURI//NFT METADATA URI
            //this.setState({allNFTS: [...this.state.allNFTS, nft]});
            
            fetch(uri).then((respo) => {
              return respo.json();
            }).then((json) => {
              console.log(json.name);
              let url = json.image.split("//")[1];
              url = "https://ipfs.io/ipfs/" + url;//NFT IMAGE REFORMAT
              json.imageURL = url;
              nft.image = url;
              nft.name = json.name;
              nft.description =json.description
              this.setState({allNFTS: [...this.state.allNFTS, nft]});
              console.log(this.state.allNFTS);
            })
          }      
      }
  }


  getAccountBal(add){
    window.ethereum
    .request({
      method: "eth_getBalance",
      params: [String(add), "latest"],///ADRES
    })
    .then((balance) =>{
      localStorage.setItem("balance",ethers.utils.parseEther(ethers.utils.formatEther(balance)));
      console.log(localStorage.getItem("balance"))
     
    }
    );
  }

  selectButtonClick(receiverTokenID,receiverNftPrice,senderTokenID,senderNftPrice) {


    receiverTokenID = Number(receiverTokenID);
    senderTokenID = Number(senderTokenID);

    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(MintABI, CONT_ADDRESS);  
    contract.handleRevert = true;//rpc mesaji

    receiverNftPrice = ethers.utils.parseEther(receiverNftPrice);
    senderNftPrice = ethers.utils.parseEther(senderNftPrice);

    this.setState({loadingFlag:true})

    contract.methods.swapToken(senderTokenID,receiverTokenID,localStorage.getItem("balance")).send({
      from:localStorage.getItem("address"),
      value: receiverNftPrice-senderNftPrice
    }
    ).then(
      (res) => {
        console.log(res)
        this.setState({loadingFlag:false})
        this.setState({confirmFlag:true})
        //REQ FONK HATA MESAJI GORUNTULE.RPC MESAJI COK BASILIYOR.
        setTimeout(function () {
          this.setState({ confirmFlag: false });
        }.bind(this), 3000);
      }
    ).catch((res =>{
        console.log(res)
        this.setState({loadingFlag:false})
        this.setState({errorFlag:true})


        setTimeout(function () {
          this.setState({ errorFlag: false });
        }.bind(this), 3000);
    }))
  }

  render() {
    //RESIM YUKLEMEYI DUZENLE
    return (
      <div className="inset-0 z-40 fixed flex flex-col items-center justify-center" >
        <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
              onClick={() => this.props.closeModal()}
            />
        
        <div className="w-[80%] max-h-[75%] bg-gray-100 py-8  rounded-xl flex flex-wrap flex-col relative justify-center">
        <div className="absolute top-0 flex flex-col items-center justify-center fixed w-full">
          <span className="w-full flex justify-center items-center py-5 text-xl font-semibold text-black">NFT'lerim</span>
          <span className="absolute right-10 font-bold text-xl hover:text-gray-600 cursor-pointer" onClick={() => this.props.closeModal()}>X</span>
        </div>

        <div className="flex gap-x-4 gap-y-8 max-h-[90%] flex-wrap overflow-x-hidden justify-center py-4">
          {this.state.allNFTS.map((nft) => {
            return(
              <Card 
                key={nft.tokenID}
                nftImage={nft.image} 
                nftName={nft.name} 
                nftDescription={nft.description}
                nftUnique={nft.uniqueState} 
                nftPrice={ethers.utils.formatEther(nft.price)} 
                buyButton={this.buyButtonClick} 
                getAccountBal={this.getAccountBal} 
                tokenId={nft.tokenID}
                buyAndSwap="hidden"
                selectButton="block"
                selectButtonClick={this.selectButtonClick}
                receiverTokenID={this.props.receiverTokenID}
                receiverNftPrice={this.props.receiverNftPrice}
              />
            )
          })}
          
        </div>
        </div>

        {this.state.loadingFlag ?
                    <div className="z-20 flex items-center justify-center gap-x-4 bg-blue-400 w-3/4 md:w-1/3 text-xs md:text-base p-3 md:p-4 text-white font-semibold shadow-md rounded-md"><ReactLoading className="text-white text-center my-auto md:mt-1" type='spin' height='16px' width='16px' /><span>İşlem gerçekleşiyor...</span></div> : null}
        {this.state.errorFlag ? <div className="z-20 flex items-center justify-center bg-red-400 w-3/4 md:w-1/3 text-xs md:text-base p-3 md:p-4 text-white font-semibold shadow-md rounded-md"> İşlem sırasında sorun oluştu.</div> : null}
        {this.state.confirmFlag ? <div className="z-20 flex items-center justify-center bg-green-400 w-3/4 md:w-1/3 text-xs md:text-base p-3 md:p-4 text-white font-semibold shadow-md rounded-md"> İşlem başarıyla gerçekleşti </div> : null}

      </div>
    );
  }
}

export default Modal;
