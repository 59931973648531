import React, { Component } from "react";
import Bzlablogo from "../img/bzlablogo.png"
import { FaTwitter, FaLinkedin, FaMoneyBillAlt } from 'react-icons/fa';
import { HiMail } from 'react-icons/hi';


class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: localStorage.getItem("address"),
    };
  }

  render() {
    return (
        <div className="w-full bg-[#2C3333] object-contain flex justify-between items-center px-2 md:px-10 gap-x-4 md:gap-x-0 py-4 md:py-2">
          <a href="https://blokzincir.bilgem.tubitak.gov.tr"><img className="w-[112px] h-auto my-2 md:w-[250px] md:h-auto" src={Bzlablogo} /></a>
          <div className="flex gap-x-2">
            <a className="w-7 h-7 md:w-10 md:h-10 text-base md:text-lg rounded-full bg-gray-200 flex items-center justify-center hover:bg-gray-300 cursor-pointer" href="https://twitter.com/bag_tr">
              <FaTwitter />
            </a>
            <a className="w-7 h-7 md:w-10 md:h-10 text-base md:text-lg rounded-full bg-gray-200 flex items-center justify-center hover:bg-gray-300 cursor-pointer" href="mailto:bagyonetici@tubitak.gov.tr">
              <HiMail />
            </a>
            <a className="w-7 h-7 md:w-10 md:h-10 text-base md:text-lg rounded-full bg-gray-200 flex items-center justify-center hover:bg-gray-300 cursor-pointer" href="https://www.linkedin.com/in/blokzincirarastirmaagi">
              <FaLinkedin />
            </a>
            <a className="w-7 h-7 md:w-10 md:h-10 text-base md:text-lg rounded-full bg-gray-200 flex items-center justify-center hover:bg-gray-300 cursor-pointer" href="https://faucet.eth.bag.org.tr/">
              <FaMoneyBillAlt />
            </a>
          </div>
          <p className="text-white font-semibold text-center text-xs md:text-base"> © 2022 <a href="https://bag.org.tr/" className="underline hover:text-gray-200">Blokzincir Araştırma Ağı</a> 
          <br />
          Her hakkı saklıdır.
          </p>
          </div>
    );
  }
}

export { Footer };