import React, { Component } from "react";
import Bzlablogo from "../img/bzlablogo.png"
import BagLogo from "../img/bag-logo.png"
import { CHAIN_ID } from "../config/keys"
import { ethers } from "ethers";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: localStorage.getItem("address"),
      buttonHide: (localStorage.getItem("address") && localStorage.getItem("address") !== 'undefined'  ? "hidden" : ""),
      balance: ""
    };
  }

  componentDidMount = async () => {
    //const acc = await window.ethereum.request({ method: "eth_requestAccounts" })
    window.ethereum.on('accountsChanged', function (acc) {
      if (acc[0]) {
        localStorage.setItem("address", acc[0])
      }
      else {
        localStorage.clear()
      }
      
    })


  }

  metamaskBaglan = async () => {
    if (typeof window.ethereum !== "undefined") {
      console.log("MetaMask is installed!");

      const chainId = await window.ethereum.request({ method: `eth_chainId` });
            const bagethChainId = CHAIN_ID

            if (chainId === bagethChainId) {
                console.log("BAGETH network");
            } else {
                console.log("Different network")
            }

            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: CHAIN_ID }],
                });
                console.log("You have succefully switched to BAGETH network")
                const acc = await window.ethereum.request({ method: "eth_requestAccounts" })
                console.log(acc);
                this.setState({ address: acc[0], buttonHide: "hidden"});
                await this.getBalance(acc[0])
            } catch (switchError) {
                // This error code indicates that the chain has not been added to MetaMask.
                console.log(switchError)

                if (switchError.code === 4902) {
                    console.log("This network is not available in your metamask, please add it")
                    // TODO Kullaniciya hatayi bildir
                }
                console.log("Failed to switch to the network")
            }


      
    } else {
      alert("Metamask Bulunamadi");
    }
  };

  getBalance = async (address) => {
    window.ethereum
      .request({
        method: "eth_getBalance",
        params: [String(address), "latest"],
      })
      .then((balance) =>
        this.setState({ balance: ethers.utils.formatEther(balance) })
      );
    if (address !== null) {
      localStorage.setItem("address", address);
    }
  }

  render() {
    return (
        <div className="w-full bg-[#2C3333] shadow-md object-contain flex justify-between items-center px-24 sticky top-0 z-20">
          <a href="/"><img className="w-[205px] h-auto my-2 md:w-[350px] md:h-auto" src={BagLogo} /></a>
          <div className="flex gap-x-6 items-center text-white">
            <a href="/">Ana Sayfa</a>
            <a href="/mint" className={"" + this.state.buttonHide == "" ? "hidden" : null}>NFT Yarat</a>
            <a href="/marketplace" className={"font-semibold text-[#24b7c3] " + (this.state.buttonHide == "" ? "hidden" : null)}>NFT Market</a>
            <a href="/mynft" className={"" + this.state.buttonHide == "" ? "hidden" : null}>NFT'lerim</a>
            <a href="/help">S.S.S</a>
            <button className={"py-1 px-2 md:py-2 md:px-4 bg-orange-500 shadow-lg rounded-lg hover:bg-orange-400 text-white font-bold cursor-pointer text-xs md:text-base " + this.state.buttonHide} onClick={() => this.metamaskBaglan()}>
            Kripto Cüzdan Bağla
            </button>        
          </div>
        </div>
    );
  }
}

export { Header };