import React, { Component } from "react";
import {Header} from "./Header";
import sssKurulum from "../img/sss-bageth-kurulum.jpg"
import sssKurulum2 from "../img/sss-bageth-kurulum-2.jpg"
import {Footer} from "./Footer"
import Banner from "../img/banner4.gif"


class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
        openButton: false,
        openButton1: false,
        openButton2: false,
        openButton3: false,
        openButton4: false,
        openButton5: false
    };
  }

  render() {
    return (
      <div className="w-full flex items-center justify-between flex-col min-h-screen gap-y-16 bg-gray-100">
        <div className="flex flex-col w-full">
        <Header />
        <div className="bg-[#395B64]/70 relative shadow-lg w-full py-20 bg-gradient-to-r from-black to-[#2C3333] flex items-center text-white text-2xl">
            <img className="w-full h-full object-cover absolute top-0 opacity-20" src={Banner}/>
            <span className="z-10 px-20">Sıkça Sorulan Sorular</span>
          </div>
          </div>
        <div className="flex flex-col items-center  h-full w-full py-4">
          <div className="flex flex-col items-center w-[80%] md:W-1/2 gap-y-16">
          <span className="flex flex-col gap-y-4 justify-center text-center bg-gray-200 border rounded-xl py-4 px-8 text-black shadow-md text-sm md:text-lg w-[100%]" >
                <div className="flex justify-between w-full items-center cursor-pointer"onClick={() => this.setState({openButton3: !this.state.openButton3})}>
                    <p>BAGETH test ağına nasıl bağlanılır?</p>
                    <button className="w-7 h-7 border-2 border-gray-600 rounded-full flex items-center text-gray-600 justify-center shadow-lg text-base hover:cursor-pointer hover:border-gray-500 hover:text-gray-500">{!this.state.openButton3 ? "+" : "-" }</button>

                </div>
                {
                    this.state.openButton3 ? 
                    (
                    <div className="flex flex-col items-center gap-y-4">
                        <span className="border border-gray-300 w-full"></span>
                        <span>NFT Market'i kullanabilmek için Metamask cüzdanınızın BAGETH test ağına bağlı olması gerekmektedir. Öncelikle Metamask eklentisine giriş yapılır ve sağ üst kısımda bulunan ağ sekmesi üzerinden 'Add Network' butunona basılır.</span>
                        <img src={sssKurulum} className="w-96 h-auto rounded-xl shadow-lg border-2 border-black" />
                        <span>Ardından açılan arayüz üzerinde şu bilgiler girilmeli ve 'Save' butonuna basılmalıdır:
                            <br />
                            <div className="border-2 border-white">
                            - Network Name: BAGETH
                            <br />
                            - New RPC URL: https://rpc.eth.bag.org.tr
                            <br />
                            - Chain ID: 12345
                            <br />
                            - Currency Symbol: ETH
                            </div>
                        </span>
                        <img src={sssKurulum2} className="w-96 h-auto rounded-xl shadow-lg border-2 border-black" />
                        <span>Bu işlemler gerçekleştirildikten sonra başarıyla BAGETH test ağına bağlanılmış olunacaktır.</span>
                    </div>
                    ) :
                    null
                }
                
              </span>

              <span className="flex flex-col gap-y-4 justify-center text-center bg-gray-200 border rounded-xl py-4 px-8 text-black shadow-md text-sm md:text-lg w-[100%]" >
                <div className="flex justify-between w-full items-center cursor-pointer" onClick={() => this.setState({openButton4: !this.state.openButton4})} >
                    <p>BAGETH test ağındaki cüzdana nasıl ETH aktarılır?</p>
                    <button className="w-7 h-7 border-2 border-gray-600 rounded-full flex items-center text-gray-600 justify-center shadow-lg text-base hover:cursor-pointer hover:border-gray-500 hover:text-gray-500">{!this.state.openButton4 ? "+" : "-" }</button>

                </div>
                {
                    this.state.openButton4 ? 
                    (
                    <div className="flex flex-col items-center gap-y-4">
                        <span className="border border-gray-300 w-full"></span>
                        <span className="">BAGETH test ağındaki cüzdana para aktarılması için: 
                            <br />
                            <a href="https://faucet.eth.bag.org.tr/" className="text-blue-500 hover:text-blue-400">Faucet</a>
                            <br />
                        </span>
                        <span>Linkine giriş yaptıktan sonra Metamask hesabına bağlanılarak para aktarma işlemi gerçekleştirilebilir.</span>
                    </div>
                    ) :
                    null
                }
                
              </span>
              
              <span className="flex flex-col gap-y-4 justify-center text-center bg-gray-200 border rounded-xl py-4 px-8 text-black shadow-md text-sm md:text-lg w-[100%]" >
                <div className="flex justify-between w-full items-center cursor-pointer" onClick={() => this.setState({openButton: !this.state.openButton})} >
                    <p>NFT market nedir?</p>
                    <button className="w-7 h-7 border-2 border-gray-600 rounded-full flex items-center text-gray-600 justify-center shadow-lg text-base hover:cursor-pointer hover:border-gray-500 hover:text-gray-500">{!this.state.openButton ? "+" : "-" }</button>

                </div>
                {
                    this.state.openButton ? 
                    (
                    <div className="flex flex-col items-center gap-y-4">
                        <span className="border border-gray-300 w-full"></span>
                        <span className="">NFT market kullanıcıların ürettikleri, sahip oldukları NFT'leri pazarlayabileceği veya farklı kullanıcılar tarafından satışa sunulmuş olan NFT'leri görüntüleyip satın alabileceği bir uygulamadır. Kullanıcıların alım-satım işlemleri Metamask Cüzdan üzerinden yapılmaktadır.</span>
                    </div>
                    ) :
                    null
                }
                
              </span>

              <span className="flex flex-col gap-y-4 justify-center text-center bg-gray-200 border rounded-xl py-4 px-8 text-black shadow-md text-sm md:text-lg w-[100%]" >
                <div className="flex justify-between w-full items-center cursor-pointer" onClick={() => this.setState({openButton1: !this.state.openButton1})} >
                    <p>NFT nasıl oluşturulur?</p>
                    <button className="w-7 h-7 border-2 border-gray-600 rounded-full flex items-center text-gray-600 justify-center shadow-lg text-base hover:cursor-pointer hover:border-gray-500 hover:text-gray-500">{!this.state.openButton1 ? "+" : "-" }</button>

                </div>
                {
                    this.state.openButton1 ? 
                    (
                    <div className="flex flex-col items-center gap-y-4">
                        <span className="border border-gray-300 w-full"></span>
                        <span className="">Bir NFT oluşturmak için Market ekranının sağ alt kısmında bulunan 'NFT Yarat' butonuna tıklanmalıdır. Ardından NFT oluşturma arayüzü karşınıza gelecektir. Bu arayüzden öncelikle bilgisayarınızda bulunan ve NFT'leştirmek istenen görüntü 'Resim Yükle' butonu aracılığı ile seçilir. Seçilen resim arayüzde görüntülendikten sonra NFT isim, açıklama ve fiyat değerleri girilir. Gerekli alanlar doldurulduktan sonra 'NFT Yarat' butonu ile NFT oluşturma işlemi başlatılır. Son olarak açılan Metamask eklentisi üzerinden hesap hareketi onaylanır ve işlem tamamlanır.</span>
                    </div>
                    ) :
                    null
                }
                
              </span>

              <span className="flex flex-col gap-y-4 justify-center text-center bg-gray-200 border rounded-xl py-4 px-8 text-black shadow-md text-sm md:text-lg w-[100%]" >
                <div className="flex justify-between w-full items-center cursor-pointer" onClick={() => this.setState({openButton2: !this.state.openButton2})} >
                    <p>Bir NFT nasıl satın alınır veya takaslanır?</p>
                    <button className="w-7 h-7 border-2 border-gray-600 rounded-full flex items-center text-gray-600 justify-center shadow-lg text-base hover:cursor-pointer hover:border-gray-500 hover:text-gray-500" >{!this.state.openButton2 ? "+" : "-" }</button>

                </div>
                {
                    this.state.openButton2 ? 
                    (
                    <div className="flex flex-col items-center gap-y-4">
                        <span className="border border-gray-300 w-full"></span>
                        <span className="">Bir NFT satın almak veya takaslamak için öncelikle Metamask Cüzdan'nınızda bakiye yeterli olmalıdır. Satın alma işleminde, market arayüzünde satın alınması istenilen NFT'nin 'Satın Al' butonuna tıklanmalıdır ve açılan Metamask eklentisindeki hesap hareketi onaylanmalıdır. Takas işleminde, market arayüzünde takaslanmak istenilen NFT'nin 'Takas' butonuna tıklanmalıdır. Sonrasında açılacak pencere üzerinde kullanıcının sahip olduğu NFT'ler görüntülenecektir. Bu pencereden kullanıcı sahip olduğu NFT'lerden hangisinin takaslanacağını seçmeli ve açılan Metamask eklentisindeki hesap hareketi onaylanmalıdır. Burada sahip olunan NFT fiyatının, takaslanmak istenen NFT fiyatından düşük veya eşit olması gerekmektedir. Aksi takdirde takaslama işlemine izin verilmeyecektir. Takas işlemi eşit fiyatlı NFT'ler üzerinden gerçekleşiyor ise ekstra bir para aktarımı söz konusu olmayacaktır fakat NFT ücretleri arasında bir fark söz konusu ise bu fiyat farkı alıcı cüzdanından tahsil edilmektedir. </span>
                    </div>
                    ) :
                    null
                }
                
              </span>

              <span className="flex flex-col gap-y-4 justify-center text-center bg-gray-200 border rounded-xl py-4 px-8 text-black shadow-md text-sm md:text-lg w-[100%]" >
                <div className="flex justify-between w-full items-center cursor-pointer" onClick={() => this.setState({openButton5: !this.state.openButton5})} >
                    <p>NFT'lerimi nasıl görüntüleyebilirim?</p>
                    <button className="w-7 h-7 border-2 border-gray-600 rounded-full flex items-center text-gray-600 justify-center shadow-lg text-base hover:cursor-pointer hover:border-gray-500 hover:text-gray-500" >{!this.state.openButton5 ? "+" : "-" }</button>

                </div>
                {
                    this.state.openButton5 ? 
                    (
                    <div className="flex flex-col items-center gap-y-4">
                        <span className="border border-gray-300 w-full"></span>
                        <span className="">NFT market arayüzünün sağ üst tarafında bulunan 'NFT'lerim' butonu üzerinden sahip olunan NFT'ler görüntülenebilir. Ayrıca bu ekran üzerinde sahip olunan NFT'lerin satış durumu ve fiyat bilgileri güncellenebilmektedir.</span>
                    </div>
                    ) :
                    null
                }
                
              </span>

            </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Help;
