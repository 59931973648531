import React, { Component } from "react";
import Web3 from "web3/dist/web3.min";
import Card from "./Card"
import {getJsonMetaData} from "../config/ipfs"
import {MintABI, CONT_ADDRESS} from "../contract/MintABI"
import { ethers } from "ethers"
import { Header } from "./Header"
import Modal from "./Modal";
import ReactLoading from 'react-loading';
import {Footer} from "./Footer"
import Banner from "../img/banner4.gif"

class Marketplace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: localStorage.getItem("address"),
      metadata: {
        name:"NFT",
        image: " ",
        description: "Empty"
      },
      price: "0",
      tokenID: " ",
      balance: 0,
      currentOwner: " ",
      totalTokenCount: 0,
      allNFTS: [],
      isModal: false,
      receiverTokenID: " ",
      receiverNftPrice: " ",
      loadingFlag: false,
      errorFlag: false,
      confirmFlag: false
    };
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.buyButtonClick = this.buyButtonClick.bind(this)
  }


  componentDidMount = async () => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(MintABI, CONT_ADDRESS);
    const totalTokenCount = await contract.methods._tokenIdCounter().call()
    this.setState({totalTokenCount: totalTokenCount})
      for (let i = 0; i < totalTokenCount; i++) {
          const nft = await contract.methods.allNFTS(i).call()
          let uri = nft.tokenURI//NFT METADATA URI
          if (nft.forSale && !nft.uniqueState) {
            //this.setState({allNFTS: [...this.state.allNFTS, nft]});
            fetch(uri).then((respo) => {
              return respo.json();
            }).then((json) => {
              console.log(json.name);
              let url = json.image.split("//")[1];
              url = "https://ipfs.io/ipfs/" + url;//NFT IMAGE REFORMAT
              json.imageURL = url;
              nft.image = url;
              nft.name = json.name;
              nft.description =json.description
              this.setState({allNFTS: [...this.state.allNFTS, nft]});
              console.log(this.state.allNFTS);
            })
          }
          
      }
  }

  openModal(id, price) {//swap onclick
    this.setState({receiverTokenID:id, receiverNftPrice:price})
    console.log(id);
    console.log(price);
    this.setState({isModal: true})
  }
  
  closeModal() {
    this.setState({isModal: false})
  }

  buyButtonClick(id,nft_price){
    id = Number(id);
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(MintABI, CONT_ADDRESS);  
    //contract.handleRevert = false;//rpc mesaji
    nft_price = ethers.utils.parseEther(nft_price)


    this.setState({loadingFlag:true});
    window.ethereum.request({ method: "eth_requestAccounts" }).then((res) => {
      console.log(res);
      const address = res[0];
      localStorage.setItem("address",address)
      return address;
    }).then((add) => {
      this.getAccountBal(add);
    });
    console.log(id)
    
    contract.methods.buyToken(id,localStorage.getItem("balance")).send({
      from:localStorage.getItem("address"),
      value: nft_price
    }
    )
    .then(
      (res) => {
        console.log(res)
        this.setState({loadingFlag:false})
        this.setState({confirmFlag:true})
        //REQ FONK HATA MESAJI GORUNTULE.RPC MESAJI COK BASILIYOR.
        setTimeout(function () {
          this.setState({ confirmFlag: false });
        }.bind(this), 3000);
      }
    ).catch((res =>{
        console.log(res)
        this.setState({loadingFlag:false})
        this.setState({errorFlag:true})


        setTimeout(function () {
          this.setState({ errorFlag: false });
        }.bind(this), 3000);
    }))
    
    
  }

  getAccountBal(add){
    window.ethereum
    .request({
      method: "eth_getBalance",
      params: [String(add), "latest"],///ADRES
    })
    .then((balance) =>{
      localStorage.setItem("balance",ethers.utils.parseEther(ethers.utils.formatEther(balance)));
      console.log(localStorage.getItem("balance"))
     
    }
    );
  }
 

  render() {
    //RESIM YUKLEMEYI DUZENLE
    return (
      <div className="w-full bg-gray-100 items-center justify-between flex flex-col min-h-screen gap-y-2">
        <button className="absolute top-0 right-0 m-5 bg-white hover:bg-black text-[#20b8c4] font-bold py-2 px-4 rounded-full"onClick={()=>window.location.href="/mynft"}>NFT'lerim</button>
        {this.state.isModal ? <Modal receiverNftPrice={this.state.receiverNftPrice} receiverTokenID={this.state.receiverTokenID} closeModal={this.closeModal} /> : null}
        <div className="flex flex-col w-full ">
        <Header />
        <div className="bg-[#395B64]/70 relative shadow-lg w-full py-20 bg-gradient-to-r from-black to-[#2C3333] flex items-center text-white text-2xl">
            <img className="w-full h-full object-cover absolute top-0 opacity-20" src={Banner}/>
            <span className="z-10 px-20">NFT Market</span>
          </div>
          </div>
        {this.state.loadingFlag ?
                    <div className="flex items-center justify-center gap-x-4 bg-blue-400 w-3/4 md:w-1/3 text-xs md:text-base p-3 md:p-4 text-white font-semibold shadow-md rounded-md"><ReactLoading className="text-white text-center my-auto md:mt-1" type='spin' height='16px' width='16px' /><span>İşlem gerçekleşiyor...</span></div> : null}
        {this.state.errorFlag ? <div className="flex items-center justify-center bg-red-400 w-3/4 md:w-1/3 text-xs md:text-base p-3 md:p-4 text-white font-semibold shadow-md rounded-md"> İşlem sırasında sorun oluştu.</div> : null}
        {this.state.confirmFlag ? <div className="flex items-center justify-center bg-green-400 w-3/4 md:w-1/3 text-xs md:text-base p-3 md:p-4 text-white font-semibold shadow-md rounded-md"> İşlem başarıyla gerçekleşti </div> : null}

        <div className="w-[95%] bg-gray-100 p-16 rounded-xl flex flex-wrap overflow-x-hidden">
        <div className="flex gap-x-4 gap-y-8 h-full flex-wrap justify-center">
          {this.state.allNFTS.map((nft) => {
            return(
              <Card 
                key={nft.tokenID}
                nftImage={nft.image} 
                nftName={nft.name} 
                nftDescription={nft.description}
                nftUnique={nft.uniqueState} 
                nftPrice={ethers.utils.formatEther(nft.price)}
                currentOwner={nft.currentOwner}
                buyButton={this.buyButtonClick} 
                getAccountBal={this.getAccountBal} 
                tokenId={nft.tokenID}
                openModal={this.openModal}
              />
            )
          })}
          
        </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Marketplace;
