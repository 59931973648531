import React, { Component } from "react";
import Web3 from "web3/dist/web3.min";
import Card from "./Card"
import {getJsonMetaData} from "../config/ipfs"
import {MintABI, CONT_ADDRESS} from "../contract/MintABI"
import { ethers } from "ethers"
import { Header } from "./Header"
import {Footer} from "./Footer"
import ModalTransfer from "./ModalTransfer";
import Banner from "../img/banner4.gif"


class MyNFT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: localStorage.getItem("address"),
      metadata: {
        name:"NFT",
        image: " ",
        description: "Empty"
      },
      price: "0",
      tokenID: " ",
      balance: 0,
      currentOwner: " ",
      totalTokenCount: 0,
      allNFTS: [],
      isModal:false,
      transferTokID: " "
    };
    this.openModalTransfer = this.openModalTransfer.bind(this)
    this.closeModalTransfer = this.closeModalTransfer.bind(this)
  }



  openModalTransfer(tokID) {//transfer onclick
    this.setState({isModal: true})
    this.setState({transferTokID:tokID})
    console.log("Transfer TokenId: " + tokID)
  }
  
  closeModalTransfer() {
    this.setState({isModal: false})
  }
  

  componentDidMount = async () => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(MintABI, CONT_ADDRESS);

    window.ethereum.request({ method: "eth_requestAccounts" }).then((res) => {
      console.log(res);
      const address = res[0];
      localStorage.setItem("address",address)
      return address;
    })

    const totalTokenCount = await contract.methods._tokenIdCounter().call()
    this.setState({totalTokenCount: totalTokenCount})
      for (let i = totalTokenCount; i >= 0; i--) {
          const nft = await contract.methods.allNFTS(i).call()
          //console.log(nft.currentOwner)
          if (nft.currentOwner.toLowerCase() == localStorage.getItem("address")) {
            let uri = nft.tokenURI//NFT METADATA URI
            //this.setState({allNFTS: [...this.state.allNFTS, nft]});
            
            fetch(uri).then((respo) => {
              return respo.json();
            }).then((json) => {
              console.log(json.name);
              let url = json.image.split("//")[1];
              url = "https://ipfs.io/ipfs/" + url;//NFT IMAGE REFORMAT
              json.imageURL = url;
              nft.image = url;
              nft.name = json.name;
              nft.description =json.description
              this.setState({allNFTS: [...this.state.allNFTS, nft]});
              console.log(this.state.allNFTS);
            })
          }      
      }
  }



  render() {
    //RESIM YUKLEMEYI DUZENLE
    return (
      <div className="w-full bg-gray-100 items-center justify-between flex flex-col min-h-screen gap-y-2">
        {this.state.isModal ? <ModalTransfer transferTokenID={this.state.transferTokID} openModalTransfer={this.openModalTransfer} closeModalTransfer={this.closeModalTransfer} /> : null}        <div className="flex flex-col w-full">
        <Header />
        <div className="bg-[#395B64]/70 relative shadow-lg w-full py-20 bg-gradient-to-r from-black to-[#2C3333] flex items-center text-white text-2xl">
            <img className="w-full h-full object-cover absolute top-0 opacity-20" src={Banner}/>
            <span className="z-10 px-20">NFT'lerim</span>
          </div>
          </div>
        <button className="absolute top-15 left-0 m-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"onClick={()=>window.location.href="/marketplace"}> {"Geri"}</button>
        <div className="w-[95%] bg-gray-100 p-16 rounded-xl flex flex-wrap overflow-x-hidden">
        <div className="flex gap-x-4 gap-y-8 h-full flex-wrap justify-center">
          {this.state.allNFTS.map((nft) => {
            return(
              <Card 
                key={nft.tokenID}
                nftImage={nft.image} 
                nftName={nft.name} 
                nftDescription={nft.description} 
                nftPrice={ethers.utils.formatEther(nft.price)}
                nftUnique={nft.uniqueState} 
                buyButton={this.buyButtonClick} 
                getAccountBal={this.getAccountBal} 
                tokenId={nft.tokenID}
                buyAndSwap="hidden"
                switchButton = {true}
                forSale = {nft.forSale}
                openModalTransfer = {this.openModalTransfer}
              />
            )
          })}
          
        </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default MyNFT;
